import React, { useContext } from 'react';
import { FormContext, IFields } from '../pages/index';

interface IProps {
  label: string;
  name: keyof IFields;
  required?: boolean;
  type?: string;
}

const Field: React.FC<IProps> = ({
  label,
  name,
  required = false,
  type = 'text',
  children,
}) => {
  const { fields, onChange } = useContext(FormContext);
  return (
    <div className="field is-horizontal">
      <div style={{ flexGrow: 2 }} className="field-label is-small">
        <label>{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            {children ? (
              children
            ) : (
              <input
                type={type}
                required={required}
                value={fields[name] || ''}
                name={name}
                className="input is-small"
                onChange={onChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Field;
