import React from 'react';
import { IconType } from 'react-icons';

interface IOption {
  Icon: IconType;
  label: string;
}

interface IProps {
  options: IOption[];
  value: string | null;
  onChange: (label: string) => void;
}

const ButtonGroup: React.FC<IProps> = ({ options, value, onChange }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="field is-grouped">
        {options.map(({ Icon, label }) => (
          <p key={label} className="control">
            <button
              type="button"
              className="button is-large has-badge-rounded"
              data-badge={value === label ? '✔' : undefined}
              onClick={() => onChange(label)}
            >
              <span className="icon">
                <Icon className="has-text-primary" />
              </span>
              <span>{label}</span>
            </button>
          </p>
        ))}
      </div>
    </div>
  );
};

export default ButtonGroup;
