import React, { useState } from 'react';
import { ButtonGroup, Field } from '../components';
import { FaCreditCard, FaMoneyCheck } from 'react-icons/fa';
import Helmet from 'react-helmet';
import '../components/style.scss';

type ThenArg<T> = T extends Promise<infer U> ? U : T;

export interface IFields {
  amount?: string;
  invoiceNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  phoneNumber?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountType?: 'checking' | 'savings';
  cardNumber?: string;
  month?: string;
  year?: string;
  cardCode?: string;
}

export const FormContext = React.createContext<{
  fields: IFields;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}>({ fields: {}, onChange: console.log });

const defaultFields: IFields = {
  accountType: 'checking',
  country: 'United States',
};

const IndexPage = () => {
  const [inFlight, setInFlight] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<'Check' | 'Credit'>('Credit');
  const [fields, setFields] = useState<IFields>(defaultFields);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target;
    setFields(prev => ({ ...prev, [name]: value }));
  };
  const submit = async () => {
    setInFlight(true);
    await getCardData()
      .then(submitPayment)
      .catch(err => {
        setMessage('Error: ' + err.message);
      });
    setInFlight(false);
  };

  const submitPayment = (
    opaqueData: ThenArg<ReturnType<typeof getCardData>>,
  ) => {
    const transactionRequest = {
      transactionType: 'authCaptureTransaction',
      amount: fields.amount,
      payment: {
        opaqueData,
      },
      order: {
        invoiceNumber: fields.invoiceNumber,
      },
      customer: {
        email: fields.email,
      },
      billTo: {
        firstName: fields.firstName,
        lastName: fields.lastName,
        company: fields.company,
        address: fields.address,
        city: fields.city,
        state: fields.state,
        zip: fields.zip,
        country: fields.country,
        phoneNumber: fields.phoneNumber,
      },
    };
    console.log(transactionRequest);
    fetch('/.netlify/functions/authnet', {
      method: 'POST',
      body: JSON.stringify(transactionRequest),
    })
      .then(async res => {
        if (res.status === 200) {
          setFields(defaultFields);
          setMessage('Your payment has been processed!');
        } else {
          console.log(await res.text());
          setMessage('Error: Please fix any errors and try again');
        }
      })
      .catch(err => {
        console.log(err);
        setMessage('Error: Please fix any errors and try again');
      });
  };

  const getCardData = (): Promise<{
    dataDescriptor: string;
    dataValue: string;
  }> =>
    new Promise((resolve, reject) => {
      const { Accept } = window as any;

      const panData =
        type === 'Credit'
          ? {
              cardData: {
                cardNumber: fields.cardNumber,
                month: fields.month,
                year: fields.year,
                cardCode: fields.cardCode,
              },
            }
          : {
              bankData: {
                accountNumber: fields.accountNumber,
                routingNumber: fields.routingNumber,
                nameOnAccount: `${fields.firstName} ${fields.lastName}`,
                accountType: fields.accountType,
              },
            };

      Accept.dispatchData(
        {
          authData: {
            clientKey:
              '3eq3jmqha6ehD5B639B3saEX82t3NTq22LhbSxZTUPBGPArbLQ5j2B3v23qYDAeN',
            apiLoginID: '32WywrS6259',
          },
          ...panData,
        },
        (obj: any) => {
          if (obj.messages.resultCode === 'Ok') {
            resolve(obj.opaqueData);
          } else {
            console.log(obj.messages.message);
            reject(new Error(obj.messages.message[0].text));
          }
        },
      );
    });
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        submit();
      }}
    >
      <FormContext.Provider value={{ fields, onChange }}>
        <Helmet>
          <title>Payments | Kobus Business Systems</title>
          <script
            type="text/javascript"
            src="https://js.authorize.net/v1/Accept.js"
          />
        </Helmet>

        <div className="column is-half is-offset-one-quarter">
          <h1 className="title has-text-primary">Kobus Business Systems</h1>

          <section className="section">
            <p className="subtitle">Payment Info</p>
            <hr />
            <ButtonGroup
              value={type}
              onChange={t => setType(t as any)}
              options={[
                { Icon: FaCreditCard, label: 'Credit' },
                { Icon: FaMoneyCheck, label: 'Check' },
              ]}
            />
            <hr />
            <Field name="invoiceNumber" label="Invoice Number" />
            <Field required name="amount" label="Amount" />
            {type === 'Check' ? (
              <React.Fragment>
                <Field required name="routingNumber" label="Routing Number" />
                <Field required name="accountNumber" label="Account Number" />
                <Field name="accountType" label="Account Type">
                  <div className="control">
                    <label className="radio">
                      <input
                        type="radio"
                        name="accountType"
                        checked={fields.accountType === 'checking'}
                        onChange={e => {
                          if (e.target.checked) {
                            setFields(prev => ({
                              ...prev,
                              accountType: 'checking',
                            }));
                          }
                        }}
                      />
                      &nbsp;Checking
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        name="accountType"
                        checked={fields.accountType === 'savings'}
                        onChange={e => {
                          if (e.target.checked) {
                            setFields(prev => ({
                              ...prev,
                              accountType: 'savings',
                            }));
                          }
                        }}
                      />
                      &nbsp;Savings
                    </label>
                  </div>
                </Field>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Field required name="cardNumber" label="Credit Card Number" />
                <Field required name="cardCode" label="CVV" />
                <Field name="month" label="Expiration Date">
                  <div className="select">
                    <select
                      required
                      name="month"
                      value={fields.month || ''}
                      onChange={onChange as any}
                    >
                      <option>MM</option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(n => (
                        <option key={n}>{n.toString().padStart(2, '0')}</option>
                      ))}
                    </select>
                  </div>
                  <div className="select">
                    <select
                      required
                      name="year"
                      value={fields.year || ''}
                      onChange={onChange as any}
                    >
                      <option>YYYY</option>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(n => (
                        <option key={n}>{new Date().getFullYear() + n}</option>
                      ))}
                    </select>
                  </div>
                </Field>
              </React.Fragment>
            )}
            <p className="subtitle">Billing Contact Info</p>
            <hr />
            <Field required name="firstName" label="First Name" />
            <Field required name="lastName" label="Last Name" />
            <Field name="company" label="Company Name" />
            <Field name="address" label="Billing Address" />
            <Field name="city" label="City" />
            <Field name="state" label="State" />
            <Field name="country" label="Country" />
            <Field name="zip" label="Postal Code" />
            <Field type="tel" name="phoneNumber" label="Phone" />
            <Field type="email" name="email" label="Email" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                disabled={inFlight}
                type="submit"
                className="button is-primary"
              >
                {inFlight ? '...' : 'Submit'}
              </button>
            </div>
          </section>
        </div>
        <div className={`modal${message ? ' is-active' : ''}`}>
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{message}</p>
              <button
                onClick={() => setMessage('')}
                type="button"
                className="delete"
                aria-label="close"
              />
            </header>
          </div>
        </div>
      </FormContext.Provider>
    </form>
  );
};

export default IndexPage;
